import { HoursTimespan } from "./HoursTimespanSelector";
import { InvalidJobsFromPools } from "realityservices-ui-components";

export default function InvalidJobsFromBatch() {
    const timeOptions = [
        { value: HoursTimespan.LastHour, label: "Last Hour" },
        { value: HoursTimespan.Last6Hours, label: "Last 6 Hours" },
        { value: HoursTimespan.Last24Hours, label: "Last 24 Hours" },
        { value: HoursTimespan.LastWeek, label: "Last Week" },
        { value: HoursTimespan.Last2Weeks, label: "Last 2 Weeks" },
        { value: HoursTimespan.LastMonth, label: "Last Month" },
        { value: HoursTimespan.LastQuarter, label: "Last Quarter" },
    ];
    const locationOptions = [
        { value: "EastUs", label: "East US" },
        { value: "CentralIndia", label: "Central India" },
        { value: "AustraliaEast", label: "Australia East" },
        { value: "NorthEurope", label: "North Europe" },
        { value: "SoutheastAsia", label: "Southeast Asia" },
        { value: "UKSouth", label: "UK South" },
        { value: "JapanEast", label: "Japan East" },
    ];

    function handleDelete(job: any) {
        if (job.dataCenter) {
            return `${window.location.origin}"/api/v1/clusters/jobId/"${job.id.toString()}/locations/${job.dataCenter.location.toString()}/terminate`;
        } else {
            return `${window.location.origin}"/api/v1/clusters/jobId/"${job.id.toString()}/locations/${job.location}/terminate`;
        }
    }

    return (
        <div>
            <InvalidJobsFromPools
                getJobReportUrl={
                    window.location.origin + "/api/v1/jobs/report?"
                }
                getActiveJobUrl={
                    window.location.origin + "/api/v1/clusters/activeJobs"
                }
                getCancelJobUrl={handleDelete}
                showFilters={true}
                title={"Invalid Jobs From Pools"}
                showTimeFilter={true}
                showLocationFilter={true}
                timeFilterOptions={timeOptions}
                locationFilterOptions={locationOptions}
                defaultTimeFilterOption={{
                    value: HoursTimespan.LastWeek,
                    label: "Last Week",
                }}
                defaultlocationFilterOptions={{
                    value: "EastUs",
                    label: "East US",
                }}
            />
        </div>
    );
}
