import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//:>--------------------------------------------------------------------------------------+
//:>
//:>  $Source: Selector.tsx $
//:>
//:>  $Copyright: (c) 2017 Bentley Systems, Incorporated. All rights reserved. $
//:>
//:>+--------------------------------------------------------------------------------------
import React from "react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
export default function Selector(props) {
    const [value, setValue] = React.useState(props.default);
    const [open, setOpen] = React.useState(false);
    function handleChange(event) {
        setValue(event === null || event === void 0 ? void 0 : event.target);
        props.onValueChanged(event === null || event === void 0 ? void 0 : event.target.value);
    }
    function handleClose() {
        setOpen(false);
    }
    function handleOpen() {
        setOpen(true);
    }
    return (_jsxs("form", { autoComplete: "off", children: [_jsx("span", { style: { fontSize: "1rem", fontWeight: 500, color: "rgba(0,0,0,0.6)", display: "block" }, children: props.label }), _jsx(FormControl, { variant: "standard", sx: { m: 0, minWidth: 120, backgroundColor: 'rgb(255,255,255)' }, children: _jsx(Select, { open: open, onClose: handleClose, onOpen: handleOpen, value: value.value, label: value.label, onChange: handleChange, inputProps: {
                        name: 'timespan'
                    }, style: {
                        color: 'rgba(0,0,0,0.6)',
                        backgroundColor: 'rgb(255,255,255)'
                    }, children: props.options.map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.value))) }, value.value) })] }));
}
